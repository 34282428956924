import React, { useEffect, useState } from 'react';
import { Button, Card, Form, ListGroup, Nav, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faFilter, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { handleRefineImagesAutomatic, handleRefineMatches, handleSpecialToolMatch } from '../../api/backend';
import { Amplify, Storage } from 'aws-amplify'
import './PdfFileUpload.scss';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from "react-i18next";
import { ImageManual, ImageManualItem, Manual, ManualItem } from '../../utils/manualUtils'
import Tooltip from 'react-bootstrap/Tooltip';
import { useRecordEvent } from 'aws-rum-react';
import { useExtractedImagesState, useI18nState, usePdfUploadState } from '../../store';
import aws_config from '../../modified_aws-export';
import { ExtractedItem } from "../shared/models/constants";
import { MultiValue } from "react-select";
import { ISpecialToolMatchPayload } from "../shared/models/specialTools";
import { fetchManufacturers, IOptionType } from "../shared/models/common";
import { useNavigate } from 'react-router-dom';
import PdfFileUpload from './PdfFileUpload';
import ManualFinder from '../ManualFinder/ManualFinder';

Amplify.configure(aws_config);

const PdfFileUploaded: React.FC = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useI18nState();
    const {
        uploadComplete,
        setUploadComplete,
        uploadedFiles,
        fileMap,
        setFileMap,
        clearUploadedFiles,
        clearFileMap,
        setExtractionFinished,
        extractedImages,
        setExtractedImages,
        specialTools,
        setSpecialTools,
        setManualsBasedOnSpecialTools,
        setManualsBasedOnImages,
    } = usePdfUploadState();
    const navigate = useNavigate();
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [threshold, setThreshold] = useState<number>(0.5);
    const [imageAutoSelectionTopK, setImageAutoSelectionTopK] = useState<number>(10);
    const [topK, setTopK] = useState<number>(5);
    const [manufacturers, setManufactures] = useState<IOptionType[]>([]);
    const [selectedManufacturers, setSelectedManufacturers] = useState<IOptionType[]>([]);
    const [itemMPoptions, setItemMPoptions] = useState<IOptionType[]>([]);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [extractOptions, setExtractOptions] = useState<IOptionType[]>([]);
    const [selectedItemMP, setSelectedItemMP] = useState<IOptionType | null>(null);
    const [selectedExtractItem, setSelectedExtractItem] = useState<IOptionType | null>(null);
    const { resetSelectedImages, setDisplayImageManuals } = useExtractedImagesState()
    const [extractType, setExtractType] = useState(ExtractedItem.SpecialTools);
    const recordEvent = useRecordEvent();
    const [checkedTools, setCheckedTools] = useState(specialTools.map(() => true));

    Storage.configure({
        aws_user_files_s3_bucket_region: aws_config.aws_user_files_s3_bucket_region,
        aws_user_files_s3_bucket: aws_config.aws_user_files_s3_bucket
    })

    useEffect(() => {
        setExtractOptions([
            { value: ExtractedItem.SpecialTools, label: t("Sidebar.SpecialTools") },
            { value: ExtractedItem.ImagesManual, label: t("Sidebar.ImagesManualSelection") },
            { value: ExtractedItem.ImagesAutomatic, label: t("Sidebar.ImagesAutomaticSelection") }
        ]);
        setSelectedExtractItem({ value: ExtractedItem.SpecialTools, label: t("Sidebar.SpecialTools") });
        fetchManufacturers().then(desiredListManufacturers => {
            setManufactures(desiredListManufacturers);
        })
    }, [selectedLanguage, t])

    useEffect(() => {
        setExtractionFinished(false)
        resetSelectedImages()
        const fetchFile = async () => {
            try {
                // Fetch the file from AWS S3
                const result = await Storage.get('REFERENCEDATA/item_mp_names.json', {
                    level: 'public',
                    customPrefix: { public: '' },
                    download: true
                });
                // Check if the file is returned as a Blob
                if (result && typeof result === 'object' && 'Body' in result) {
                    const { Body } = result as { Body?: Blob };

                    if (Body instanceof Blob) {
                        // Read the Blob as text
                        const text = await Body.text()

                        // Parse the JSON content
                        const data = JSON.parse(text);
                        const initialOptions: IOptionType[] = data.map((item: string) => ({
                            value: item,
                            label: item
                        }));

                        setItemMPoptions(initialOptions)

                    } else {
                        console.error('No file content found or incorrect file type.');
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchFile();
    }, [resetSelectedImages, setExtractionFinished]);

    useEffect(() => {
        setCheckedTools(specialTools.map(() => true));
    }, [specialTools])

    const handleClosePDFUploaded = () => {
        setUploadComplete(false);
        setFileMap({});
        clearUploadedFiles();
        clearFileMap();
        setSpecialTools([]);
        setExtractedImages([]);
        setExtractionFinished(false);
        setSelectedManufacturers([]);
        setThreshold(0.5);
        setTopK(5);
        setExtractType(ExtractedItem.SpecialTools);
        setSelectedExtractItem({ value: ExtractedItem.SpecialTools, label: t("Sidebar.SpecialTools") });
        navigate("/special-tools-finder")
    };

    const handleImageAutoSelectionTopK = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setImageAutoSelectionTopK(parseInt(value, 10));
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setTopK(parseInt(value, 10));
        }
    };

    const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setThreshold(parseFloat(value));
    };

    const handleSelectExtractType = (selectedValue: IOptionType) => {
        setSelectedExtractItem(selectedValue);
        handleChangeExtractedType(selectedValue.value)
    };

    const handleChangeExtractedType = (extractedType: string) => {
        setExtractType(extractedType);
        setDisplayImageManuals(false);
    }

    const handleSelectChange = (selectedValue: IOptionType) => {
        if (selectedValue) {
            setSelectedItemMP(selectedValue);
        }
    };

    const handleSelectManufacturers = (values: MultiValue<IOptionType>) => {
        setSelectedManufacturers(values.map((item) => ({
            value: item.value,
            label: item.label
        })));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoadingSubmit(true);
        recordEvent('StartExtractionOfSpecialTools', { 'description': 'Extraction of special tools for uploaded PDF files was started' });

        const filter = selectedManufacturers.length > 0 ? {
            "$or": selectedManufacturers.map((manufacturer) => ({
                "Manufacturer": manufacturer.value
            }))
        } : {};

        const payload: ISpecialToolMatchPayload = {
            topK: topK,
            image_threshold: threshold,
            itemMp: selectedItemMP?.value ?? '',
            fileIds: JSON.stringify(fileMap),
            setExtractedImages: setExtractedImages,
            setExtractionFinished: setExtractionFinished,
            t: t,
            recordEvent: recordEvent,
            filter: filter
        };

        try {
            const [{
                specialTools: specialToolsResponse,
                manuals: manualsBasedOnSpecialTools
            }, {
                extractedImages: extractedImages,
                manualsBasedOnImages: manualsBasedOnImages
            }] = await handleSpecialToolMatch(payload)

            if (Array.isArray(specialToolsResponse) && specialToolsResponse.length > 0) {
                setSpecialTools(specialToolsResponse)
            }
            if (manualsBasedOnSpecialTools.length > 0) {
                const matchingManuals: Manual[] = [];
                manualsBasedOnSpecialTools.forEach((item: ManualItem) => {
                    if (item) {
                        const manual: Manual = {
                            manualName: item.manualName,
                            manualId: item.manualID,
                            matchedTools: item.matchedSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            additionalTools: item.additionalSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            missingTools: item.missingSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            itemMpText: item.itemMpText
                        };
                        matchingManuals.push(manual);
                    }
                })
                setManualsBasedOnSpecialTools(matchingManuals)
            } else {
                console.log("No matching manuals found")
            }

            if (manualsBasedOnImages.length > 0) {
                const matchingManuals: ImageManual[] = [];
                manualsBasedOnImages.slice(0, imageAutoSelectionTopK).forEach((item: ImageManualItem) => {
                    if (item) {
                        const manual: ImageManual = {
                            manualName: item.manualName,
                            manualId: item.manualId,
                            itemMpText: item.itemMpText,
                            imageFileNameCount: item.imageFileNameCount,
                            imageFileNamesFound: item.imageFileNamesFound,
                            totalImageCount: item.totalImageCount
                        };
                        matchingManuals.push(manual);
                    }
                })
                setManualsBasedOnImages(matchingManuals);
            } else {
                console.log("No matching manuals found")
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsLoadingSubmit(false);
        }
    };


    const onFilterMatches = async (filteredTools: string[], topK: number) => {
        const stringifiedTools = JSON.stringify(filteredTools);
        const manualResponse = await handleRefineMatches(stringifiedTools, topK);
        if (manualResponse.length > 0) {
            const matchingManuals: Manual[] = [];
            manualResponse.forEach((item: ManualItem) => {
                if (item) {
                    const manual: Manual = {
                        manualName: item.manualName,
                        manualId: item.manualID,
                        matchedTools: item.matchedSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        additionalTools: item.additionalSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        missingTools: item.missingSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        itemMpText: item.itemMpText,
                    };
                    matchingManuals.push(manual);
                }
            });
            setManualsBasedOnSpecialTools(matchingManuals);
        }
    };

    const onRefineImagesAutomatic = async () => {
        const imageNames = extractedImages.map((obj: any) => Object.keys(obj)).flat();
        const filter = selectedManufacturers.length > 0 ? {
            "$or": selectedManufacturers.map((manufacturer) => ({
                "Manufacturer": manufacturer.value
            }))
        } : {};
        const { manualsBasedOnImages, newExtractedImages } = await handleRefineImagesAutomatic(imageNames, threshold, filter);
        const matchingManuals = manualsBasedOnImages
            .slice(0, imageAutoSelectionTopK)
            .filter((item: ImageManualItem) => item && item)
            .map((item: ImageManualItem) => ({
                manualName: item.manualName,
                manualId: item.manualId,
                itemMpText: item.itemMpText,
                imageFileNameCount: item.imageFileNameCount,
                imageFileNamesFound: item.imageFileNamesFound,
                totalImageCount: item.totalImageCount
            } as ImageManual));
        setManualsBasedOnImages(matchingManuals);
        setExtractedImages(newExtractedImages);
    };

    const handleApplyFilter = async (event: any) => {
        event.preventDefault();
        setPopoverVisible(false);
        setIsLoading(true);
        if (extractType === ExtractedItem.SpecialTools) {
            const selectedTools = specialTools.filter((tool, index) => checkedTools[index]);
            await onFilterMatches(selectedTools, topK);
        } else {
            await onRefineImagesAutomatic();
        }
        setIsLoading(false);
    }

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('PdfFileUpload.ToolTipText')}
        </Tooltip>
    );

    const manufacturerDisabledTooktip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("ExtractedImages.ManufacturerDisabledTooktip")}
        </Tooltip>
    );


    const handleCheckboxChange = (index: number) => {
        const newCheckedTools = [...checkedTools];
        newCheckedTools[index] = !newCheckedTools[index];
        setCheckedTools(newCheckedTools);
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTopK(parseInt(value));
    };


    const popover = (
        <Popover id="filter-popover">
            <Popover.Header as="h3">{t("OnlyMatchToOEM.FilterTitle")}</Popover.Header>
            <Popover.Body>
                <Form onSubmit={handleApplyFilter}>
                    {extractType === ExtractedItem.SpecialTools ? (
                        <>
                            <Form.Group>
                                <Form.Label>{t("SpecialTool.SpecialTools")}</Form.Label>
                                {specialTools.map((tool, index) => (
                                    <Form.Check
                                        aria-label={`option-${index}`}
                                        key={index}
                                        type="checkbox"
                                        id={`tool-${index}`}
                                        label={tool}
                                        checked={checkedTools[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                        disabled={isLoading}
                                    />
                                ))}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("PdfFileUpload.TopKResults")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={topK}
                                    onChange={handleNumberChange}
                                    disabled={isLoading}
                                />
                            </Form.Group>
                        </>
                    ) : extractType === ExtractedItem.ImagesAutomatic ? (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    {t("AutomaticImageSelection.Threshold")}: {threshold}
                                </Form.Label>
                                <Form.Control
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    type="range"
                                    value={threshold}
                                    onChange={handleThresholdChange}
                                    className="dropdown-menu-wide mx-auto"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("PdfFileUpload.TopKResults")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={imageAutoSelectionTopK}
                                    disabled={isLoadingSubmit}
                                    onChange={handleImageAutoSelectionTopK}
                                    className='dropdown-menu-wide mx-auto'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t("Filter.manufacturer")}(s)</Form.Label>
                                <CreatableSelect
                                    className="card border-0 d-md-block"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={manufacturers}
                                    defaultValue={selectedManufacturers}
                                    placeholder={t("Filter.manufacturer")}
                                    isSearchable
                                    onChange={(items) => handleSelectManufacturers(items)}
                                />
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group>
                            <Form.Label>{t("Filter.manufacturer")}(s)</Form.Label>
                            <CreatableSelect
                                className="card border-0 d-md-block"
                                closeMenuOnSelect={false}
                                isMulti
                                options={manufacturers}
                                defaultValue={selectedManufacturers}
                                placeholder={t("Filter.manufacturer")}
                                isSearchable
                                onChange={(items) => handleSelectManufacturers(items)}
                            />
                        </Form.Group>
                    )}

                    <div className="text-center mt-3">
                        <Button variant="secondary" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}{t("DropZone.loading")}
                                </>
                            ) : (
                                t("SpecialTool.FilterButton")
                            )}
                        </Button>
                    </div>
                </Form>
            </Popover.Body>
        </Popover >
    );


    return uploadComplete ? (
        specialTools.length > 0 || extractedImages.length > 0 ? (
            <div className="upload-pdf-container">
                <div className="page-header">
                    <Button
                        variant="secondary"
                        onClick={handleClosePDFUploaded}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} className="ms-2" />
                        <span>{t("Button.back")}</span>
                    </Button>
                    <div className="page-title">{t("Manual.ManualFinder")}</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <Nav className="navbar-custom" variant="underline" activeKey={extractType} onSelect={e => handleChangeExtractedType(e ?? ExtractedItem.ImagesManual)}>
                            <Nav.Item>
                                <Nav.Link eventKey={ExtractedItem.ImagesManual}>{t("Sidebar.ImagesManualSelection")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={ExtractedItem.ImagesAutomatic}>{t("Sidebar.ImagesAutomaticSelection")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={ExtractedItem.SpecialTools}>{t("Sidebar.SpecialTools")}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div>
                        <OverlayTrigger trigger="click" placement="left" rootClose overlay={popover}
                            show={isPopoverVisible}
                            onToggle={(nextShow) => setPopoverVisible(nextShow)}>
                            <Button size="sm" variant="secondary" onClick={() => setPopoverVisible(true)}>
                                <FontAwesomeIcon
                                    icon={faFilter}
                                />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="page-body d-flex flex-column manual-finder-container">
                    <ManualFinder
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        extractType={extractType}
                    />
                </div>
            </div>
        ) : (
            <div className="upload-pdf-container">
                <div className="page-header">
                    <Button
                        variant="secondary"
                        onClick={handleClosePDFUploaded}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} className="ms-2" />
                        <span>{t("Button.back")}</span>
                    </Button>
                    <div className="page-title">{t("Manual.ManualFinder")}</div>
                </div>
                <div className="page-body d-flex justify-content-center align-items-center">
                    <Card className="upload-pdf-card">
                        <Card.Body>
                            <Form onSubmit={handleSubmit} className="d-flex flex-column upload-form">
                                <div className="d-flex flex-column upload-form-item">
                                    <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                        <span>{t("PdfFileUpload.UploadedFiles")}</span>
                                    </div>
                                    <div className="uploaded-file-names">
                                        <ListGroup>
                                            {uploadedFiles.length > 0 ? (
                                                uploadedFiles.map((file: File, index) => (
                                                    <ListGroup.Item key={index}>
                                                        {file.name}
                                                    </ListGroup.Item>
                                                ))
                                            ) : (
                                                <ListGroup.Item>{t("PdfFileUpload.NoFiles")}</ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </div>
                                </div>
                                <div className="d-flex flex-column upload-form-item">
                                    <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                        <span>ItemMp</span>
                                        <div>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <CreatableSelect
                                        isClearable
                                        className='card border-0 d-md-block'
                                        closeMenuOnSelect={false}
                                        options={itemMPoptions}
                                        value={selectedItemMP ? [selectedItemMP] : []}
                                        isDisabled={isLoadingSubmit}
                                        placeholder={t("PdfFileUpload.ItemMPPlaceholder")}
                                        isSearchable
                                        onChange={(choice) => handleSelectChange(choice as IOptionType)}
                                    />
                                </div>
                                <div className="d-flex flex-column upload-form-item">
                                    <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                        <span>{t("Filter.manufacturer")}(s)</span>
                                    </div>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={manufacturerDisabledTooktip}
                                    >
                                        <span>
                                            <CreatableSelect
                                                isDisabled={extractType === ExtractedItem.SpecialTools || isLoadingSubmit}
                                                className='card border-0 d-md-block'
                                                closeMenuOnSelect={false}
                                                isMulti
                                                options={manufacturers}
                                                defaultValue={selectedManufacturers}
                                                placeholder={t("Filter.manufacturer")}
                                                isSearchable
                                                onChange={(items) => handleSelectManufacturers(items)}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                                <div className="d-flex flex-column upload-form-item">
                                    <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                        <span>{t("Sidebar.Info")}</span>
                                    </div>
                                    <CreatableSelect
                                        className='card border-0 d-md-block'
                                        isDisabled={isLoadingSubmit}
                                        options={extractOptions}
                                        value={selectedExtractItem ? [selectedExtractItem] : []}
                                        onChange={(choice) => handleSelectExtractType(choice as IOptionType)}
                                    />
                                </div>
                                {extractType === ExtractedItem.SpecialTools ? (
                                    <div className="d-flex flex-column upload-form-item">
                                        <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                            <span>{t("PdfFileUpload.TopKResults")}</span>
                                        </div>
                                        <Form.Control
                                            type="number"
                                            value={topK}
                                            disabled={isLoadingSubmit}
                                            onChange={handleInputChange}
                                            className='dropdown-menu-wide mx-auto'
                                        />
                                    </div>
                                ) : (extractType === ExtractedItem.ImagesAutomatic ? (
                                    <>
                                        <div className="d-flex flex-column upload-form-item">
                                            <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                                <span>{t("AutomaticImageSelection.Threshold")}: {threshold}</span>
                                            </div>
                                            <Form.Control
                                                disabled={isLoadingSubmit}
                                                min="0" max="1" step="0.1"
                                                type="range"
                                                value={threshold}
                                                onChange={handleThresholdChange}
                                                className='dropdown-menu-wide mx-auto'
                                            />
                                        </div>
                                        <div className="d-flex flex-column upload-form-item">
                                            <div className="d-flex flex-row align-items-baseline upload-form-title-wapper">
                                                <span>{t("PdfFileUpload.TopKResults")}</span>
                                            </div>
                                            <Form.Control
                                                type="number"
                                                value={imageAutoSelectionTopK}
                                                disabled={isLoadingSubmit}
                                                onChange={handleImageAutoSelectionTopK}
                                                className='dropdown-menu-wide mx-auto'
                                            />
                                        </div>
                                    </>
                                ) : <></>)}
                                <div className="text-center">
                                    <Button type="submit" variant="secondary" disabled={isLoadingSubmit}>
                                        {isLoadingSubmit ? <> <Spinner animation="border"
                                            size="sm" />{' '}{t("DropZone.loading")}</> : t("Button.extractSpecialTools")}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                        <button onClick={handleClosePDFUploaded} className="close-button" disabled={isLoadingSubmit}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </Card>
                </div>
            </div>
        )
    ) : (
        <PdfFileUpload />
    );
};


export default PdfFileUploaded;
