import React, {useEffect, useState} from "react";
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import {AppNavbar} from "./components";

import {Amplify} from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import {AuthState, useAuthentication, useFilterState, useI18nState, usePdfUploadState, useResultsState,} from "./store";
import {setLocalItem} from "./store/slices/pdfuploadSlice";
import awsconfig from "./modified_aws-export";
import Footer from "./components/Footer/Footer";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";
import {useDetectAdBlock} from "adblock-detect-react";
import tecAllianceLogo from './assets/TecAlliance-Logo-Web.svg';
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

Amplify.configure(awsconfig);

function App() {
  const { authState, authInitialized, initializeAuthentication, signIn } =
    useAuthentication();
  const { getResultCount } = useResultsState();
  const {
    uploadComplete,
    clearUploadedFiles,
    clearFileMap,
    setUploadComplete,
    setExtractedImages,
    setSpecialTools,
  } = usePdfUploadState();
  const { emptyFilter } = useFilterState()
  const { setLanguage } = useI18nState();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const params = new URLSearchParams(location.search);
  const openInNewTab = params.get("openInNewTab") === "true";
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (!authInitialized) {
      initializeAuthentication();
    }
  }, [authInitialized, initializeAuthentication]);

  React.useEffect(() => {
    // setLanguage(selectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLanguage]);

  useEffect(() => {
    document.title = t("Title_landing_page");
  }, [t]);

  const [activeKey, setActiveKey] = useState<string>("/image-matcher");

  useEffect(() => {
    // Update activeKey based on current pathname
    const path = location.pathname;
    if (adBlockDetected) {
      toast(t("AdblockAlert"), { type: "warning" });
    }
    if (path.startsWith("/image-matcher")) {
      setActiveKey("/image-matcher");
    } else {
      setActiveKey(path);
    }
  }, [location]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    selectedKey: string
  ) => {
    event.preventDefault();

    if (selectedKey !== activeKey) {
      const resultCount = getResultCount();
      if (activeKey === "/image-matcher" && resultCount > 0) {
        setLocalItem("openInNewTab", false);
        setShowModal(true);
        setNavigateTo(selectedKey);
      } else if (activeKey === "/special-tools-finder" && uploadComplete) {
        setNavigateTo(selectedKey);
        setShowModal(true);
      } else if (
        (activeKey === "/image-matcher" && resultCount === 0) ||
        (activeKey === "/special-tools-finder" && !uploadComplete)
      ) {
        navigate(selectedKey);
        setActiveKey(selectedKey);
      }
      else {
        navigate(selectedKey);
      }
    }
  };

  const handleConfirm = () => {
    if (navigateTo) {
      if (
        navigateTo === "/image-matcher" &&
        activeKey === "/special-tools-finder"
      ) {
        clearUploadedFiles();
        clearFileMap();
        setUploadComplete(false);
        setSpecialTools([]);
        setExtractedImages([]);
        emptyFilter();
      }

      navigate(navigateTo);
      setActiveKey(navigateTo);
    }

    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        {t("OnlyMatchToOEM.MatchesPairsToolTips")}
      </Tooltip>
  );

  return (
    <>
      <div className="App">
        <AppNavbar></AppNavbar>
        {authState === AuthState.SignedIn && !openInNewTab ? (
          <Nav className="navbar-custom" variant="pills" activeKey={activeKey}>
            <Nav.Item>
              <Nav.Link
                eventKey="/image-matcher"
                style={{ color: "black" }}
                onClick={(e) => handleClick(e, "/image-matcher")}
              >
                Image Matcher
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="/special-tools-finder"
                style={{ color: "black" }}
                onClick={(e) => handleClick(e, "/special-tools-finder")}
              >
                {t("Manual.ManualFinder")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <OverlayTrigger trigger="hover" placement="right" overlay={renderTooltip} rootClose>
              <Nav.Link
                eventKey="/only-match-to-oem"
                style={{ color: "black" }}
                onClick={(e) => handleClick(e, "/only-match-to-oem")}
              >
                {t("OnlyMatchToOEM.MatchesPairs")}
              </Nav.Link>
              </OverlayTrigger>
            </Nav.Item>
            <ConfirmationModal
              show={showModal}
              title={t("ConfirmationModal.ConfirmationTitle")}
              message={t("ConfirmationModal.ConfirmationMessage")}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          </Nav>
        ) : null}

        <main className="login-container">
          {authState === AuthState.SignedIn ? (
            <>
              <Outlet />
            </>
          ) :
            <div className="unauth-container">
              <div className="unauth-wrapper">
                <img src={tecAllianceLogo} alt="tecalliance-logo" />
                <hr />
                <span>Welcome! You are not signed in yet. Please click "<span className="hyper-link" onClick={signIn}>{t("Login")}</span>" or use the login button in the top-right corner to sign in.</span>
              </div>
            </div>
          }
        </main>
        <Footer />
      </div>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;
